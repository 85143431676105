import React from 'react'

import Layout from '../components/layout'
import PostList from '../components/post-list'
import SEO from '../components/seo'
const IndexPage = () => (
  <Layout>
    <SEO title="Blog" keywords={[`gatsby`]} />
    <PostList />
  </Layout>
)

export default IndexPage
