import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { Box, Media, Image, Content, Section } from 'react-bulma-components';
import {formatDistance} from 'date-fns';
const PostList = () => (
  <StaticQuery
    query={graphql`
    query Post {
      posts: allContentfulBlogPost {
        edges {
          node {
            createdAt
            updatedAt
            id
            title
            subtitle
            content {
              childContentfulRichText {
                html
              }
            }
            headerImage {
              file {
                url
              }
            }     
          }
        }
      }
    }
    `}
    render={
      data =>       
        <Section>
          {data.posts.edges.map(post => 
              <Box key={post.node.id}>

                <Media onClick={() => navigate('/post', {state:{post: post}})}>
                  <Media.Item  renderAs="figure" position="left">
                    <Image src={post.node.headerImage.file.url} size={64} />
                  </Media.Item>
                  <Media.Item>
                    <Content>
                      <p>
                        <strong>{post.node.title}</strong> 
                        <small>{formatDistance(new Date(post.node.updatedAt), new Date())}</small>
                        <br />
                        {post.node.subtitle}
                      </p>
                    </Content>
                  </Media.Item>
                </Media>
              </Box>
          )}  
          </Section>  
    }
  />
)
export default PostList
