import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Navbar, Heading } from 'react-bulma-components';
const Header = ({ siteTitle }) => (
  <Navbar color="primary">
    <Navbar.Brand>
      <Link to="/"  className="navbar-item">
        <Heading size={6} >          
          {siteTitle}
        </Heading>  
      </Link>
    </Navbar.Brand>

  </Navbar>

)

Heading.propTypes = {
  siteTitle: PropTypes.string,
}

Heading.defaultProps = {
  siteTitle: ``,
}

export default Header